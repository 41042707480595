<template>
  <div>
    <img src="../../assets/images/404.png" style="width:auto;height:580px;margin-left: 28%"/>
  </div>
</template>
<script>
// 404页面
export default {
  name: 'Error'
}
</script>
